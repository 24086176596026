import { t } from "@circle-react/custom_i18n";
import { useBroadcastReport } from "@circle-react-shared/Emails/BroadcastReport/BroadcastReportContext";
import { ViewRecipientListButton } from "@circle-react-shared/Emails/BroadcastReport/LinkClicksStats/ViewRecipients/ViewRecipientListButton";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { RecipientCount } from "./RecipientCount";
import { StatBox } from "./StatBox";

const localeNamespace = "settings.workflows.view";

interface EmailInlineStatsProps {
  onFullReportClick: () => void;
}

export function EmailInlineStats({ onFullReportClick }: EmailInlineStatsProps) {
  const { data } = useBroadcastReport();

  if (!data) {
    return null;
  }

  const {
    delivered,
    opened,
    clicked,
    recipient_count: recipientCount,
    sent,
  } = data;

  const total = sent || recipientCount;

  return (
    <div className="flex flex-col gap-y-2">
      <ViewRecipientListButton
        recipient_type="all_recipients"
        className="flex items-center gap-x-1"
      >
        <RecipientCount recipientCount={recipientCount} />
      </ViewRecipientListButton>
      <div className="border-primary overflow-hidden rounded-lg border">
        <div className="divide-primary flex items-center justify-around divide-x py-6 text-center">
          <StatBox
            total={total}
            label={t([localeNamespace, "delivered"])}
            value={delivered}
          />
          <StatBox
            total={total}
            label={t([localeNamespace, "opened"])}
            value={opened}
          />
          <StatBox
            total={total}
            label={t([localeNamespace, "clicked"])}
            value={clicked}
          />
        </div>
        <button
          type="button"
          onClick={onFullReportClick}
          className="bg-secondary hover:bg-tertiary flex w-full items-center justify-center gap-x-2 py-2.5"
        >
          <Icon type="20-analytics" size={16} />
          <Typography.LabelSm weight="medium" color="text-very-dark">
            {t([localeNamespace, "show_full_report"])}
          </Typography.LabelSm>
        </button>
      </div>
    </div>
  );
}
