import { useMemo } from "react";
import { t } from "i18n-js";
import { omit } from "lodash";
import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { useContactsInWorkflowsEnabled } from "@circle-react/hooks/useContactsInWorkflowsEnabled";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { BadgeV2 } from "@circle-react-uikit/BadgeV2";
import { Tab } from "@circle-react-uikit/TabV2";
import { Typography } from "@circle-react-uikit/Typography";
import type { WORKFLOW_RESULT } from "../../constants";
import { availableActions } from "../Actions";
import { Result } from "../Table/Result";
import { ExpandableCode } from "./ExpandableCode";
import { HistoryActionMessage } from "./HistoryActionMessage";

export interface Log {
  message: {
    status: (typeof WORKFLOW_RESULT)[keyof typeof WORKFLOW_RESULT];
    action: {
      action_type: string;
      api_params: object;
    };
    request_details: {
      body: object;
      url: string;
    };
    response_details: {
      body: object;
      url: string;
    };
  };
  created_at: string;
  updated_at: string;
  action: string;
}

interface HistoryActionProps {
  log: Log;
  status: string;
}

export const HistoryAction = ({ log, status }: HistoryActionProps) => {
  const isContactsInWorkflowsEnabled = useContactsInWorkflowsEnabled();

  const { message, created_at: createdAt = "" } = log;

  const {
    request_details: requestDetails = {},
    response_details: responseDetails = {},
  } = message;

  const selectedAction = useMemo(
    () =>
      availableActions.find(
        ({ value }) => value === message?.action?.action_type,
      ),
    [message?.action?.action_type],
  );

  const actionName = useMemo(
    () => selectedAction?.label ?? message?.action?.action_type,
    [message?.action?.action_type, selectedAction?.label],
  );

  const eventDetailsJson = useMemo(() => {
    const requestDetailsLogs = omit(requestDetails, ["url"]);
    return {
      request: JSON.stringify(requestDetailsLogs, null, 2),
      response: JSON.stringify(responseDetails, null, 2),
    };
  }, [requestDetails, responseDetails]);

  if (!message?.action?.action_type) {
    return null;
  }

  const date = formattedDateTime({
    dateTime: new Date(createdAt),
    format: "short_date_at_short_time",
  });

  return selectedAction?.isCompactHistoryView ? (
    <div className="border-dark rounded-lg border">
      <HistoryActionMessage log={log} status={status} />
    </div>
  ) : (
    <div className="border-dark rounded-lg border">
      <Tab.Group>
        <div className="bg-secondary border-dark flex flex-col gap-3.5 rounded-t-lg border-b px-6 pt-5">
          <div className="flex justify-between">
            <div className="flex flex-col gap-1">
              <div>
                <Typography.LabelMd weight="semibold">
                  {actionName}
                </Typography.LabelMd>
              </div>
              <div className="flex flex-col gap-2 md:flex-row">
                <Result result={message?.status} isBadge={false} />
                <Typography.LabelSm color="text-default" as="time">
                  {date}
                </Typography.LabelSm>
              </div>
            </div>
            <div>
              {isContactsInWorkflowsEnabled &&
                selectedAction?.isApplicableToMembersOnly && (
                  <TippyV2
                    content={t("settings.workflows.members_only_tooltip")}
                    interactive={false}
                  >
                    <BadgeV2 label={t("settings.workflows.members_only")} />
                  </TippyV2>
                )}
            </div>
          </div>
          <Tab.List>
            <Tab>{t("settings.workflows.history.info")}</Tab>
            <Tab>{t("settings.workflows.history.data_in")}</Tab>
            <Tab>{t("settings.workflows.history.data_out")}</Tab>
          </Tab.List>
        </div>
        <Tab.Panels>
          <Tab.Panel className="px-6 py-5">
            <HistoryActionMessage log={log} status={status} />
          </Tab.Panel>
          <Tab.Panel className="p-2">
            <ExpandableCode code={eventDetailsJson?.request} />
          </Tab.Panel>
          <Tab.Panel className="p-2">
            <ExpandableCode code={eventDetailsJson?.response} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
