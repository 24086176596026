import { notifyBugsnag } from "@/react/helpers/bugsnagHelpers";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import {
  GENERAL_ERROR,
  PIX_MODAL_CLOSED,
  buildErrorMessage,
  getReferral,
} from "../helpers";
import { useCard } from "./paymentMethods/useCard";
import { usePix } from "./paymentMethods/usePix";

const manageError = (error: any, handleError: any, options: any) => {
  if (error?.cause?.error_details === PIX_MODAL_CLOSED.error_details) return;

  notifyBugsnag(error);
  console.error("Error: unexpected error occurred: ", error);

  handleError(
    buildErrorMessage(
      {
        message: error.message,
        error_details: error?.cause?.error_details || GENERAL_ERROR,
      },
      options,
    ),
  );
};

export const useOnSubmit = ({ stripe, elements }: any) => {
  const { handleError, setIsProcessingPayment, loginUrl } =
    usePaywallCheckoutContext();
  const cardHooks = useCard({ stripe, elements });
  const pixHooks = usePix({ stripe });

  const paymentMethodHookStrategies: any = {
    pix: pixHooks,
    card: cardHooks,
  };

  const onSubmit = async (formData: any) => {
    if (getReferral()) {
      formData.referral_metadata = getReferral();
    }

    const { onSubmitMutation } =
      paymentMethodHookStrategies[formData.payment_method_type];

    try {
      setIsProcessingPayment(true);
      await onSubmitMutation.mutateAsync(formData);
    } catch (error: any) {
      manageError(error, handleError, { loginUrl });
    } finally {
      setIsProcessingPayment(false);
    }
  };

  return {
    onSubmit,
  };
};
