import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useIsPaywallPaymentSettingsAvailable } from "@circle-react/components/Paywalls/Admin/PaymentSettings/hooks/useIsPaywallPaymentSettingsAvailable";
import { useIsPaywallTaxAvailable } from "@circle-react/components/Paywalls/Admin/TaxSettings/hooks/useIsPaywallTaxAvailable";
import { Layout } from "@circle-react/components/SettingsApp/Layout";
import { usePunditUserContext } from "@circle-react/contexts";
import { isPromoCodeEnabled } from "@circle-react/helpers/communityHelpers";
import { useIsMarketingHubStatusVisible } from "@circle-react/hooks/useIsMarketingHubStatus";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import {
  USAGE_SECTIONS,
  UsageContextProvider,
} from "@circle-react/providers/UsageContext";
import { useRouteHelpers } from "@circle-react/routes/Main/useRouteHelpers";
import { SettingsRoutes } from "@circle-react/routes/Settings";
import { useIsWorkflowsEnabled } from "@circle-react-shared/Workflows";
import { Loader } from "@circle-react-uikit/Loader";

export const SettingsApp = () => {
  const { isLoading, currentCommunity, currentCommunitySettings } =
    usePunditUserContext();

  const isPromoCodeEnabledForCommunity = isPromoCodeEnabled(currentCommunity);
  const history = useHistory();
  const isWorkflowsEnabled = useIsWorkflowsEnabled();
  const { isSettingsPage } = useRouteHelpers();
  const { isV3Enabled } = useIsV3();

  const isPaywallTaxAvailable =
    useIsPaywallTaxAvailable() || !currentCommunity?.payment_processor;

  const isPaywallPaymentSettingsAvailable =
    useIsPaywallPaymentSettingsAvailable();

  const isMarketingHubEmailBroadcastsVisible = useIsMarketingHubStatusVisible();

  useEffect(() => {
    const unListen = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => unListen();
  });

  if (isLoading) {
    return <Loader />;
  }

  if (!isSettingsPage) {
    return null;
  }

  const isAffiliatesEnabled = currentCommunity.affiliates_feature_flag_enabled;

  return (
    <UsageContextProvider section={USAGE_SECTIONS.ADMIN}>
      <Layout
        shouldHideMainHeader={false}
        hideAppBar={isPromoCodeEnabledForCommunity}
      >
        <SettingsRoutes
          isPaywallTaxAvailable={isPaywallTaxAvailable}
          isAffiliatesEnabled={isAffiliatesEnabled}
          isWorkflowsEnabled={isWorkflowsEnabled}
          isPaywallPaymentSettingsAvailable={isPaywallPaymentSettingsAvailable}
          isMarketingHubEmailBroadcastsVisible={
            isMarketingHubEmailBroadcastsVisible
          }
          isGamificationEnabled={currentCommunitySettings.gamification_enabled}
          isV3Enabled={isV3Enabled}
        />
      </Layout>
    </UsageContextProvider>
  );
};
