import { communityMemberChargeApi } from "@circle-react/api/communityMemberChargeApi";
import { paywallCheckoutApi } from "@circle-react/api/paywallCheckoutApi";
import { isPaid } from "@circle-react/helpers/communityMemberChargeHelpers";
import { GENERAL_ERROR, buildErrorMessage } from "../../helpers";

export const handleCheckoutError = (
  handleError: any,
  loginUrl: string,
  error: any,
) => handleError(buildErrorMessage(error, { loginUrl }));

export const redirectToCheckoutConfirmation = (
  checkoutResponse: any,
  checkoutConfirmationUrl: string,
) => {
  const processorId =
    checkoutResponse?.subscription_processor_id ||
    checkoutResponse?.payment_intent_processor_id ||
    checkoutResponse?.invoice_processor_id;
  const url = `${checkoutConfirmationUrl}${processorId}`;

  window.webview?.paymentSuccessful?.();
  return window.open(url, "_self");
};

const PAYMENT_POLLING_TIMEOUT_MILLISECONDS = 2000;
const PAYMENT_POLLING_MAX_TRIES = 30;

export const verifyPayment = async (
  handleError: any,
  handleCheckoutError: any,
  arePostCreateActionsRequired: boolean,
  checkoutConfirmationUrl: string,
  checkoutResponseBody: any,
  tries: any,
  didRequiredAdditionalActions: boolean = false,
) => {
  // Poll backend to check payment status every 2 seconds for 30 times
  const {
    payment_intent_processor_id: paymentIntentProcessorId,
    invoice_processor_id: invoiceProcessorId,
  } = checkoutResponseBody;

  if (tries > PAYMENT_POLLING_MAX_TRIES) {
    handleError(GENERAL_ERROR);
    return;
  }

  const processorId = paymentIntentProcessorId ?? invoiceProcessorId;
  const response = await communityMemberChargeApi.show(processorId);

  if (response.ok) {
    const charge = await response.json();
    if (isPaid(charge)) {
      if (didRequiredAdditionalActions && arePostCreateActionsRequired) {
        const response = await paywallCheckoutApi.complete(
          checkoutResponseBody,
        );
        if (!response.ok) {
          const responseError = await response.json();
          handleCheckoutError(responseError);
          return;
        }
      }
      return redirectToCheckoutConfirmation(
        checkoutResponseBody,
        checkoutConfirmationUrl,
      );
    }
  }
  await new Promise(resolve =>
    setTimeout(resolve, PAYMENT_POLLING_TIMEOUT_MILLISECONDS),
  );
  await verifyPayment(
    handleError,
    handleCheckoutError,
    arePostCreateActionsRequired,
    checkoutConfirmationUrl,
    checkoutResponseBody,
    tries + 1,
    didRequiredAdditionalActions,
  );
  return undefined;
};
