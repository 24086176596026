import type { ComponentPropsWithRef } from "react";
import { useFormContext } from "react-hook-form";
import { t } from "@circle-react/custom_i18n";
import { Form } from "@circle-react-uikit/Form";
import { Typography } from "@circle-react-uikit/Typography";
import { I18N_ROOT } from "./constants";

export function SenderEmail(props: ComponentPropsWithRef<typeof Form.Input>) {
  const { getValues } = useFormContext();
  const [senderEmailDomain] = getValues(["sender_email_domain"]);

  return (
    <div className="flex flex-col gap-y-2">
      <Form.Input
        suffix={
          <div className="whitespace-nowrap py-1">
            <Typography.LabelMd>{`@${senderEmailDomain}`}</Typography.LabelMd>
          </div>
        }
        {...props}
      />
      <Typography.LabelXs color="text-default">
        {t([I18N_ROOT, "sender_email_description"])}
      </Typography.LabelXs>
    </div>
  );
}
