import { addDays } from "date-fns";

export const STATUS = Object.freeze({
  INCOMPLETE: "incomplete",
  ACTIVE: "active",
  TRIAL: "trial",
  PAST_DUE: "past_due",
  CANCELED: "canceled",
});

const PAYWALL_PRICE_STATUS = Object.freeze({
  SUBSCRIPTION: "active",
  ONETIME: "onetime",
  INSTALLMENTS: "installments",
});

export const isTrial = subscription => subscription?.status === STATUS.TRIAL;
export const isPastDue = subscription =>
  subscription?.status === STATUS.PAST_DUE;

export const hasInstallmentPricing = subscription =>
  subscription?.paywall_price?.price_type === PAYWALL_PRICE_STATUS.INSTALLMENTS;

export const hasSubscriptionPricing = subscription =>
  subscription?.paywall_price?.price_type === PAYWALL_PRICE_STATUS.SUBSCRIPTION;

export const hasOnetimePricing = subscription =>
  subscription?.paywall_price?.price_type === PAYWALL_PRICE_STATUS.ONETIME;

export const SEARCHABLE_STATUS = [
  STATUS.ACTIVE,
  STATUS.TRIAL,
  STATUS.PAST_DUE,
  STATUS.CANCELED,
];

export const ACTIVE_STATUS = [STATUS.ACTIVE, STATUS.TRIAL, STATUS.PAST_DUE];

export const calculateTrialDaysEndsDate = (
  trialDaysParam,
  date = new Date(),
) => {
  const trialDays = Number(trialDaysParam) > 0 ? Number(trialDaysParam) : 0;
  return addDays(date, trialDays);
};
