import { useMemo } from "react";
import { usePunditUserContext } from "@circle-react/contexts/punditUserContext";
import {
  isAdminData,
  useGetMarketingHubStatus,
} from "@circle-react/hooks/useGetMarketingHubStatus";

export const useMarketingEmailLimits = () => {
  const { data } = useGetMarketingHubStatus();
  const {
    currentCommunitySettings: {
      marketing_hub_limits_enabled: MarketingHubLimitsEnabled,
    },
  } = usePunditUserContext();

  const shouldShowEmailNotices = useMemo(
    () => !(!isAdminData(data) || !MarketingHubLimitsEnabled),
    [data, MarketingHubLimitsEnabled],
  );

  const isSendingMarketingEmailsAllowed = useMemo(() => {
    if (!isAdminData(data) || !MarketingHubLimitsEnabled) {
      return true;
    }

    return data.limits.allowance > 0;
  }, [data, MarketingHubLimitsEnabled]);

  const marketingHubData = useMemo(() => {
    if (!MarketingHubLimitsEnabled) {
      return null;
    }

    if (isAdminData(data)) {
      return data;
    }

    return null;
  }, [data, MarketingHubLimitsEnabled]);

  return {
    shouldShowEmailNotices,
    isSendingMarketingEmailsAllowed,
    marketingHubData,
  };
};
