import { useEffect, useState } from "react";
import classNames from "classnames";
import { Sidebar } from "@/react/components/Events/NewEventsSpace/EventDetailPage/Sidebar";
import { Carousel } from "@/react/components/Spaces/ImageSpace/FeedView/Carousel";
import { useCurrentPostContext } from "@circle-react/contexts";
import { isImagePost } from "@circle-react/helpers/postHelpers";
import { isEventPost } from "@circle-react/helpers/postHelpers";
import { usePostList } from "@circle-react/hooks/posts/usePostList";
import { Modal } from "@circle-react-shared/uikit/ModalV2";
import { TranscriptsPlayer } from "@circle-react-uikit/TranscriptsPlayer";
import { CommentsSection } from "../PostViewListItem/CommentsSection";
import { EngagementActions } from "../PostViewListItem/EngagementActions";
import { PostBody } from "../PostViewListItem/PostBody";
import { PostCoverImage } from "../PostViewListItem/PostCoverImage";
import { PostHeader } from "../PostViewListItem/PostHeader";
import { useCurrentPostHelpers } from "../useCurrentPostHelpers";
import { PostBodySkeleton } from "./SkeletonLoader/PostBodySkeleton";
import { useCommentFocus } from "./hooks/useCommentFocus";

export interface ModalBodyProps {
  autofocusReplyForm?: boolean;
  isRenderedOutsideTheSpace?: boolean;
  onPostDestroy: () => void;
  post: any;
  shouldOpenCommentPage?: boolean;
  isLoading?: boolean;
}

export const ModalBody = ({
  autofocusReplyForm = true,
  isRenderedOutsideTheSpace = false,
  onPostDestroy,
  post,
  shouldOpenCommentPage = false,
  isLoading = false,
}: ModalBodyProps) => {
  const {
    record: {
      is_comments_enabled: isCommentsEnabled,
      event_setting_attributes: event_setting,
    },
    onPostChange,
  } = useCurrentPostContext();
  const { isPostBodyEmpty } = useCurrentPostHelpers();

  const { refetch: refetchPosts } = usePostList();

  const doesPostHaveCoverImage = Boolean(post?.cover_image_url);

  const liveRoomSettingAttributes =
    event_setting?.live_stream_room_setting_attributes;
  const recordingUrl = liveRoomSettingAttributes?.recording_url;
  const recordingTranscriptId =
    liveRoomSettingAttributes?.recording_transcript_id;
  const recordingThumbnailUrl = liveRoomSettingAttributes?.recording_thumbnail;
  const postingRecording =
    liveRoomSettingAttributes?.auto_post_recording_enabled;

  const [isContentVisible, setIsContentVisible] = useState(!isLoading);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (!isLoading) {
      timer = setTimeout(() => setIsContentVisible(true), 50);
    } else {
      setIsContentVisible(false);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isLoading]);

  const {
    commentsFormRef,
    showCommentsAndFocusOnForm,
    toggleComment,
    shouldShowComments,
  } = useCommentFocus({
    initialFocus: autofocusReplyForm,
  });

  if (isLoading) {
    return <PostBodySkeleton />;
  }

  return (
    <Modal.Body className="!px-0 !pt-0">
      <div
        className={classNames(
          "flex flex-col gap-5",
          "transition-opacity duration-300 ease-in-out",
          {
            "opacity-0": !isContentVisible,
            "opacity-100": isContentVisible,
          },
        )}
      >
        <PostCoverImage post={post} className="md:!rounded-none" />
        <div
          className={classNames("px-5 md:px-8", {
            "pt-3": !doesPostHaveCoverImage,
          })}
        >
          <PostHeader
            post={post}
            onChange={onPostChange}
            refetchPosts={refetchPosts}
            hideShareLink
            hideBookmarkButton
            hidePinnedToTopIcon
            onPostDestroy={onPostDestroy}
            hideAuthorHeadline={false}
            tagsLimit={1}
            tagsShowMoreLabelVariant="numbers"
            metaInfoWrapperClassName=""
            isRenderedOutsideTheSpace={isRenderedOutsideTheSpace}
          />
          <div
            className={classNames("space-y-5", {
              "mt-5": isPostBodyEmpty,
            })}
          >
            {isEventPost(post) && (
              <Sidebar
                onChange={post => {
                  onPostChange?.(post);
                }}
                shouldDisplayRsvpAction
              />
            )}
            {recordingUrl && postingRecording && (
              <TranscriptsPlayer
                src={recordingUrl}
                type="video/mp4"
                mediaTranscriptId={recordingTranscriptId}
                poster={recordingThumbnailUrl}
              />
            )}
            {isImagePost(post) && (
              <Carousel
                onPostChange={onPostChange}
                onPostDestroy={onPostDestroy}
              />
            )}
            <PostBody />
            <div>
              <EngagementActions
                className="!px-0"
                toggleComment={toggleComment}
                showCommentAndFocusCommentForm={showCommentsAndFocusOnForm}
                shouldOpenCommentPage={shouldOpenCommentPage}
              />
              {isCommentsEnabled && shouldShowComments && (
                <CommentsSection
                  commentsFormRef={commentsFormRef}
                  autofocusReplyForm={false}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal.Body>
  );
};
