import { uniqueId } from "lodash";
import { planTiers } from "@circle-react/helpers/planHelpers";
import type { SpaceType } from "@circle-react/types/Space";

export const WORKFLOW_STATUS = {
  ALL: "all",
  ACTIVE: "active",
  INACTIVE: "inactive",
  DRAFT: "draft",
  PUBLISHED: "published",
  ARCHIVED: "archived",
  IN_PROGRESS: "in_progress", // Time delays can easily put the workflow in this state
} as const;

export const WORKFLOW_RUN_STATUS = {
  SUCCESS: "success",
  PARTIAL_SUCCESS: "partial_success",
  IN_PROGRESS: "in_progress",
  FAILED: "failed",
  SKIPPED: "skipped",
  DUPLICATE_SKIPPED: "duplicate_skipped",
} as const;

export const WORKFLOW_TYPE = {
  DYNAMIC: "dynamic",
  BULK_ACTION: "bulk_action",
  SCHEDULED: "scheduled",
  ALL: "all",
} as const;

export const SCHEDULE_TYPE = {
  ONE_TIME: "one_time",
  RECURRING: "recurring",
} as const;

export const WORKFLOW_TABS = {
  DYNAMIC: "dynamic",
  BULK_ACTION: "bulk_action",
  SCHEDULED: "scheduled",
  ARCHIVED: "archived",
} as const;

export const ORDERED_WORKFLOW_TABS = [
  WORKFLOW_TABS.DYNAMIC,
  WORKFLOW_TABS.BULK_ACTION,
  WORKFLOW_TABS.SCHEDULED,
  WORKFLOW_TABS.ARCHIVED,
] as const;

export const BULK_ACTION_ENTRY_RULE_TYPE_ICON = {
  member: "user",
} as const;

export const WORKFLOW_RESULT = {
  SUCCESS: "success",
  PARTIAL_SUCCESS: "partial_success",
  IN_PROGRESS: "in_progress",
  SKIPPED: "skipped",
  DUPLICATE_SKIPPED: "duplicate_skipped",
  FAILED: "failed",
  CANCELLED: "cancelled",
} as const;

export const getDefaultAction = (actionType?: string) => ({
  action_type: actionType ?? "",
  id: uniqueId("action_"),
  api_params: {},
});

export const TRIGGER_RESOURCE_TYPE = {
  SPACE: "Space",
  PAYWALL: "Paywall",
  EVENT: "Posts::Event",
  MEMBER_TAG: "MemberTag",
  SPACE_GROUP: "SpaceGroup",
  POST_BASIC: "Posts::Basic",
  COMMUNITY: "Community",
  COURSE: "Space",
  PROFILE_FIELD: "ProfileField",
  RECURRING_EVENT_SETTING: "RecurringEventSetting",
  LESSON: "Lesson",
  SECTION: "Section",
  GAMIFICATION_LEVEL: "Gamification::Level",
  FORM: "Form",
} as const;

export const SPACE_POST_TYPES: Record<string, SpaceType> = {
  BASIC: "basic",
  EVENT: "event",
  MEMBERS: "members",
  COURSE: "course",
  CHAT: "chat",
} as const;

export const SEND_TO_WEBHOOK_ELIGIBLE_PLAN_TIERS = [
  planTiers.enterprise,
  planTiers.plus,
] as const;
