import classNames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import { useThemeContext } from "@/react/providers";
import { SidebarBadge } from "@circle-react/components/Spaces/SidebarV2/PlatformMenu/SidebarBadge";
import { shouldRenderLockIcon } from "@circle-react/components/Spaces/utils";
import { Icon } from "@circle-react/components/shared/Icon";
import { usePunditUserContext } from "@circle-react/contexts";
import { isIntendedOnNewTab } from "@circle-react/helpers/browserHelpers";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { useResetSpaceSidebarNotifications } from "@circle-react/hooks/useSpaceSidebarNotifications";
import type { Space } from "@circle-react/types";
import { EmojiRenderer } from "@circle-react-shared/EmojiRenderer";
import { useGetSidebarLinkClass } from "./useGetSidebarLinkClass";

export interface SpaceViewProps {
  space?: Space;
  buttonLink?: string;
  spaceNotificationDetail?: {
    unread_content_count: number;
    unread_notifications_count: number;
  };
}

interface LocationState {
  prevLocation?: Location;
}

export const SpaceView = ({
  space = {
    id: "",
    emoji: "",
    name: "",
    is_private: false,
    is_space_member: false,
    show_lock_icon_for_non_members: false,
    custom_emoji_url: "",
    custom_emoji_dark_url: "",
  },
  buttonLink = "/",
  spaceNotificationDetail = {
    unread_content_count: 0,
    unread_notifications_count: 0,
  },
}) => {
  const { getActiveInactiveClasses, getUnreadClasses } =
    useGetSidebarLinkClass();
  const { currentCommunityMember } = usePunditUserContext();
  const isAdmin = isCommunityAdmin(currentCommunityMember);
  const { currentAppearance } = useThemeContext();
  const { isV3Enabled } = useIsV3();
  const location = useLocation<LocationState | undefined>();
  const { state } = location;
  const { prevLocation } = state ?? {};
  const isInvokedFromPostModalAndFromFeed =
    Boolean(prevLocation) && !prevLocation?.pathname?.startsWith("/c");

  const { unread_content_count, unread_notifications_count = 0 } =
    spaceNotificationDetail ?? {};

  const hasUnreadContent = unread_content_count > 0;
  const hasNotifications = unread_notifications_count > 0;
  const shouldUseLockIcon = shouldRenderLockIcon({
    shouldShowLockIconForNonMembers:
      space.show_lock_icon_for_non_members && !space.is_space_member,
    isPrivate: space.is_private,
    isAdmin,
  });

  const { resetUnreadCount } = useResetSpaceSidebarNotifications([space?.id]);

  return (
    <div draggable="false">
      <NavLink
        className={isActive =>
          classNames(
            "group flex items-center py-1.5",
            getActiveInactiveClasses(
              isActive,
              isInvokedFromPostModalAndFromFeed,
            ),
            getUnreadClasses(hasUnreadContent),
            {
              "my-px px-2": !isV3Enabled,
              "h-8.5 !rounded-lg px-4 transition-colors duration-75":
                isV3Enabled,
            },
          )
        }
        draggable="false"
        data-id={space.id}
        to={buttonLink ? buttonLink : {}}
        onClick={e => {
          if (isIntendedOnNewTab(e)) {
            void resetUnreadCount();
          }
        }}
      >
        <EmojiRenderer
          className="flex !h-5 !max-h-5 !w-5 items-center leading-none"
          emojiClassName={
            !isV3Enabled ? "text-lg pl-px" : "text-sm justify-center"
          }
          emoji={space.emoji}
          appearance={currentAppearance}
          customEmojiUrl={space.custom_emoji_url}
          customEmojiDarkUrl={space.custom_emoji_dark_url}
          shouldUseLockIcon={shouldUseLockIcon}
          lockIcon={<Icon size={16} type="lock-v2" className="shrink-0" />}
        />
        <h4
          className={classNames("ml-2 max-w-full truncate text-current", {
            "text-sm": !isV3Enabled,
            "text-xs-plus": isV3Enabled,
            "font-normal": !hasUnreadContent && !isV3Enabled,
            "font-medium": !hasUnreadContent && isV3Enabled,
            "font-semibold": hasUnreadContent,
          })}
          data-testid="space-name"
        >
          {space.name}
        </h4>
        {(hasUnreadContent || hasNotifications) && (
          <SidebarBadge isGray={!hasNotifications}>
            {unread_notifications_count > 0
              ? unread_notifications_count
              : unread_content_count > 99
              ? "99+"
              : unread_content_count}
          </SidebarBadge>
        )}
      </NavLink>
    </div>
  );
};
