import { t } from "i18n-js";
import { DateAvatar } from "@circle-react/components/Events/NewEventsSpace/shared/DateAvatar";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { dateComponents } from "@circle-react/helpers/dateTimeHelpers/dateComponents";
import { dateToTimeString } from "@circle-react/helpers/dateTimeHelpers/getLocalizedDate";
import { getDayName } from "@circle-react/helpers/dateTimeHelpers/getName";

export interface EventData {
  starts_at: string;
  ends_at: string;
  time_zone_abbreviations: string;
  start_date: string;
  time_zone: string;
}

export interface SidebarEventTimestampProps {
  eventData: EventData;
}

export const SidebarEventTimestamp = ({
  eventData,
}: SidebarEventTimestampProps) => {
  const {
    starts_at: startsAt,
    ends_at: endsAt,
    time_zone_abbreviations: timeZoneAbbreviations,
    start_date: startDate,
    time_zone: timeZone,
  } = eventData;

  const startDay = getDayName(startDate);
  const endDay = getDayName(endsAt);

  const { dayOfMonth: startDayOfMonth, shortMonthString: startMonthString } =
    dateComponents(startsAt, timeZone);

  const { dayOfMonth: endDayOfMonth, shortMonthString: endMonthString } =
    dateComponents(endsAt, timeZone);

  const isOneDayEvent = startDayOfMonth === endDayOfMonth;

  const startTimeStamp = `${startDay}, ${startMonthString} ${startDayOfMonth}`;
  const endTimeStamp = `${endDay}, ${endMonthString} ${endDayOfMonth}`;

  return (
    <div className="flex flex-row items-center space-x-6">
      <DateAvatar dateString={startDate} timeZone={timeZone} />
      {isOneDayEvent ? (
        <div data-testid="event-date">
          <div>
            <Typography.LabelSm weight="semibold">
              {startTimeStamp}
            </Typography.LabelSm>
          </div>
          <div>
            <Typography.LabelSm>
              {`${dateToTimeString(startsAt, timeZone)} - ${dateToTimeString(
                endsAt,
                timeZone,
              )} ${timeZoneAbbreviations}`}
            </Typography.LabelSm>
          </div>
        </div>
      ) : (
        <div data-testid="event-date">
          <div className="mb-4">
            <Typography.LabelSm weight="semibold" as="div">
              {t("events.timestamp.from")} {startTimeStamp}
            </Typography.LabelSm>
            <Typography.LabelSm as="div">
              {dateToTimeString(startsAt, timeZone)} {timeZoneAbbreviations}
            </Typography.LabelSm>
          </div>
          <div>
            <Typography.LabelSm weight="semibold" as="div">
              {t("events.timestamp.to")} {endTimeStamp}
            </Typography.LabelSm>
            <Typography.LabelSm as="div">
              {dateToTimeString(endsAt, timeZone)} {timeZoneAbbreviations}
            </Typography.LabelSm>
          </div>
        </div>
      )}
    </div>
  );
};
