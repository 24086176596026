import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { ALL_CONTACTS } from "@circle-react/components/SettingsApp/Workflows/components/Events/ContactTypeSelector";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useUnPaginateQuery } from "@circle-react/hooks/reactQuery/useUnPaginateQuery";
import { FormTextSelect } from "@circle-react-uikit/Form/FormTextSelect";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";

const i18nRoot = "settings.workflows.form";

export const FormSelector = () => {
  const { register } = useFormContext();
  const { records: forms = [], isLoading } = useUnPaginateQuery({
    api: internalApi.forms.index,
  });

  if (isLoading) {
    return <Loader center />;
  }

  let options: {
    value: number;
    label: string;
  }[] = [];

  if (forms) {
    options = forms.map(({ id, name }) => ({
      value: id,
      label: name ?? "",
    }));
  }

  return (
    <>
      <input
        type="hidden"
        className="hidden"
        {...register("trigger.resource_type")}
      />
      <input
        type="hidden"
        className="hidden"
        {...register("trigger.contact_type", {
          value: ALL_CONTACTS,
        })}
      />
      <FormTextSelect
        name="trigger.resource_id"
        label={
          <Typography.LabelSm weight="semibold">
            {t([i18nRoot, "events.received_form_submission.choose"])}
          </Typography.LabelSm>
        }
        options={options}
        rules={{
          required: t("settings.workflows.form.errors.required"),
        }}
        emptyMessage={t(
          "settings.workflows.form.events.received_form_submission.error",
        )}
      />
    </>
  );
};
