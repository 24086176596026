import { t } from "i18n-js";
import { useIsFormsEnabled } from "@/react/hooks/useIsFormsEnabled";
import { TRIGGER_RESOURCE_TYPE } from "../../../constants";
import type { EventOption } from "../types";
import { FormResource } from "./FormResource";
import { FormSelector } from "./FormSelector";

export const receivedSubmission: Readonly<EventOption> = {
  label: t("settings.workflows.form.events.received_form_submission.title"),
  value: "received_form_submission",
  group: t("settings.workflows.form.event_groups.forms"),
  description: t(
    "settings.workflows.form.events.received_form_submission.description",
  ),
  formComponent: FormSelector,
  resource: TRIGGER_RESOURCE_TYPE.FORM,
  viewComponent: FormResource,
  icon: "email",
  released: useIsFormsEnabled,
};
