import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useBoolean } from "react-use";
import invariant from "tiny-invariant";
import { getBroadcastQueryKey } from "@circle-react/components/SettingsApp/Emails/hooks/useGetBroadcast";
import { t } from "@circle-react/custom_i18n";
import { BroadcastTipTapReadonlyView } from "@circle-react-shared/Emails/EmailPreview/BroadcastTipTapReadonlyView";
import { Button } from "@circle-react-shared/uikit/Button";
import { SkeletonLoader } from "@circle-react-shared/uikit/SkeletonLoader";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { useActions, useCurrentAction } from "../../../ActionRule";
import { FormEmailContent } from "./FormEmailContent";
import { useUpsertBroadcast } from "./useUpsertBroadcast";

const localeNamespace = "settings.workflows.form";

export function EmailContentField() {
  const { watch, setValue } = useFormContext();
  const queryClient = useQueryClient();
  const { paramsPath } = useCurrentAction();

  const emailContent = watch(`${paramsPath}.email_content`);
  const existingBroadcastId = watch(`${paramsPath}.broadcast_id`);

  const { onUpsert } = useUpsertBroadcast(
    existingBroadcastId ? Number(existingBroadcastId) : null,
  );
  const [isContentShown, toggleIsContentOpen] = useBoolean(false);

  const { doesActionhasServerId, saveAction } = useActions();
  const { action, path } = useCurrentAction();

  useEffect(() => {
    const createBroadcast = async () => {
      if (doesActionhasServerId(action) && !existingBroadcastId) {
        const data = await onUpsert();
        const broadcastId = data?.id;
        invariant(
          broadcastId,
          "Broadcast ID is required to start writing email",
        );
        setValue(`${paramsPath}.broadcast_id`, broadcastId);
        await saveAction({ path, action, skipOpenNext: true });
      }
    };

    void createBroadcast();
  }, []);

  const handleStartWriting = async () => {
    const data = await onUpsert();
    const broadcastId = data?.id;
    invariant(broadcastId, "Broadcast ID is required to start writing email");
    setValue(`${paramsPath}.broadcast_id`, broadcastId);
    toggleIsContentOpen();
  };

  const closeFullScreenEditor = () => {
    toggleIsContentOpen();
    void queryClient.invalidateQueries(
      getBroadcastQueryKey(Number(existingBroadcastId)),
    );
  };

  return (
    <div className="flex flex-col">
      <div className="border-primary flex justify-between rounded-b-none rounded-t-lg border px-5 py-4">
        <div className="flex flex-col gap-y-1">
          <Typography.LabelSm weight="semibold" color="text-very-dark">
            {t([localeNamespace, "email_content"])}
          </Typography.LabelSm>
          <Typography.LabelXs color="text-default">
            {t([localeNamespace, "email_content_description"])}
          </Typography.LabelXs>
        </div>
        <div>
          <Button
            variant="secondary"
            type="button"
            onClick={handleStartWriting}
          >
            {emailContent ? t("edit") : t([localeNamespace, "start_writing"])}
          </Button>
        </div>
      </div>
      <div className="bg-secondary border-primary h-64 overflow-y-auto rounded-b-lg border-x border-b py-6">
        <div className="bg-primary mx-auto max-w-[500px] rounded-lg p-8 shadow-sm">
          {emailContent ? (
            <BroadcastTipTapReadonlyView content={emailContent} />
          ) : (
            <SkeletonLoader variant="email-preview" />
          )}
        </div>
      </div>
      {existingBroadcastId && (
        <FormEmailContent
          isOpen={isContentShown}
          broadcastId={Number(existingBroadcastId)}
          onToggle={closeFullScreenEditor}
        />
      )}
    </div>
  );
}
