/* eslint-disable eslint-comments/no-restricted-disable -- TODO: Refactor this file */

/* eslint-disable sonarjs/cognitive-complexity -- TODO: Refactor this file */

/* eslint-disable max-lines -- TODO: Refactor this file */
import { CardElement } from "@stripe/react-stripe-js";
import { useMutation } from "react-query";
import { notifyBugsnag } from "@/react/helpers/bugsnagHelpers";
import { paywallCheckoutApi } from "@circle-react/api/paywallCheckoutApi";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import { isDigitalWallet } from "@circle-react/helpers/paywallCheckoutHelpers";
import { isPaywallFailure } from "@circle-react/types";
import {
  GENERAL_ERROR,
  STRIPE_CONNECTION_ERROR,
  getReferral,
} from "../../helpers";
import {
  handleCheckoutError,
  redirectToCheckoutConfirmation,
  verifyPayment,
} from "./helpers";
import { usePaymentMethodDataParams } from "./usePaymentMethodDataParams";

export const useCard = ({ stripe, elements }: any) => {
  const {
    currentCommunity,
    checkoutConfirmationUrl,
    loginUrl,
    handleError,
    setIsProcessingPayment,
    getSelectedPrice,
    isCardInfoRequired,
    isSetupIntentRequired,
    arePostCreateActionsRequired,
    previewMutation,
    isPaywallDigitalWalletEnabled,
    stripePaymentMethodType,
    setPaywallFailure,
  } = usePaywallCheckoutContext();

  const { paymentMethodDataParams } = usePaymentMethodDataParams();

  const handleCheckoutAttemptError = (error: any) => {
    if (isPaywallFailure(error)) {
      setPaywallFailure(error.failure);
    } else {
      handleCheckoutError(handleError, loginUrl, error);
    }
  };
  const verifyCheckoutAttemptPayment = async (
    checkoutResponseBody: any,
    tries: number,
    didRequiredAdditionalActions: boolean = false,
  ) =>
    await verifyPayment(
      handleError,
      handleCheckoutAttemptError,
      arePostCreateActionsRequired,
      checkoutConfirmationUrl,
      checkoutResponseBody,
      tries,
      didRequiredAdditionalActions,
    );
  const submitStripeElement = async (): Promise<boolean> => {
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError({
        message: submitError.message,
        disablePayButton: false,
      });
      setIsProcessingPayment(false);
      return false;
    }
    return true;
  };

  const selectedPrice = getSelectedPrice();
  if (isPaywallDigitalWalletEnabled && elements && previewMutation.data) {
    elements.update({ amount: previewMutation.data.amount_due_now });
  }

  const processPaymentInStripe = async (checkoutResponseBody: any) => {
    const {
      payment_intent_processor_id: paymentIntentProcessorId,
      payment_intent_client_secret: paymentIntentClientSecret,
    } = checkoutResponseBody;

    let didRequiredAdditionalActions = false;

    if (!stripe || !elements) {
      handleError(STRIPE_CONNECTION_ERROR); // Stripe.js has not yet loaded.
      return;
    }
    if (!paymentIntentProcessorId && !paymentIntentClientSecret) {
      // No payment intent? We are looking at a "free" subscription/one-time payment. In these
      // cases, no payment occurs, so no need to confirm with Stripe just with our backend.
      await verifyCheckoutAttemptPayment(
        checkoutResponseBody,
        0,
        didRequiredAdditionalActions,
      );
      return;
    }
    const paymentIntentResult = await stripe.retrievePaymentIntent(
      paymentIntentClientSecret,
    );
    if (paymentIntentResult.error) {
      handleError({
        message: paymentIntentResult.error.message,
        disablePayButton: true,
      });
      return;
    }

    const paymentIntent = paymentIntentResult.paymentIntent;
    if (paymentIntent.status === "canceled") {
      handleError(GENERAL_ERROR);
      return;
    }
    if (paymentIntent.status === "succeeded") {
      await verifyCheckoutAttemptPayment(
        checkoutResponseBody,
        0,
        didRequiredAdditionalActions,
      );
      return;
    }

    let confirmPaymentResult;
    if (isPaywallDigitalWalletEnabled) {
      // If we reach this point and we required a setup intent, we need to resubmit the Stripe element
      // before we can confirm the payment intent. That's because the first submission was for the setup
      // intent, not the payment intent.
      if (isSetupIntentRequired) {
        const didSubmitWork = await submitStripeElement();
        if (!didSubmitWork) {
          return;
        }
      }

      confirmPaymentResult = await stripe.confirmPayment({
        elements,
        clientSecret: checkoutResponseBody.payment_intent_client_secret,
        confirmParams: {
          return_url: window.location.href,
          ...paymentMethodDataParams,
        },
        redirect: "if_required",
      });
    } else {
      confirmPaymentResult = await stripe.confirmCardPayment(
        checkoutResponseBody.payment_intent_client_secret,
        { payment_method: { card: elements.getElement(CardElement) } },
      );
    }

    didRequiredAdditionalActions = true;

    if (
      confirmPaymentResult.error ||
      confirmPaymentResult.paymentIntent.status !== "succeeded"
    ) {
      if (didRequiredAdditionalActions && arePostCreateActionsRequired) {
        const response = await paywallCheckoutApi.cancel({
          ...checkoutResponseBody,
          processor_error: confirmPaymentResult.error,
        });
        const responseError = await response.json();
        if (responseError.failure) {
          handleCheckoutAttemptError(responseError);
          return;
        }
      }
      handleError(
        confirmPaymentResult.error
          ? {
              message: confirmPaymentResult.error.message,
              disablePayButton: false,
            }
          : GENERAL_ERROR,
      );
    } else {
      const data = { ...checkoutResponseBody };
      // Stripe does not automatically set the payment method as default if the member uses a digital
      // wallet (e.g. Apple and Google Pay) to complete the checkout. We delegate this responsibility
      // to the backend by passing the payment method ID we get after confirming the payment intent to
      // the backend to ensure consistency across all payment methods.
      if (isDigitalWallet(stripePaymentMethodType)) {
        data.processor_payment_method_id =
          confirmPaymentResult.paymentIntent.payment_method;
      }
      await verifyCheckoutAttemptPayment(data, 0, didRequiredAdditionalActions);
    }
  };

  const execCheckoutRequest = async (formData: any) => {
    // 3. Request BE to fulfill order (checkout) Create payment intent in stripe and user if needed
    const checkoutResponse = await paywallCheckoutApi.create_DEPRECATED({
      formData,
    });

    if (checkoutResponse.ok) {
      const checkoutResponseBody = await checkoutResponse.json();

      // We only redirect to the confirmation page if the trial does not require upfront payment.
      // If the trial requires upfront payment, we need to process the payment as it may require
      // additional actions (e.g. 3DS authentication) from the member and we our backend needs to
      // run all necessary post-create actions.
      if (
        selectedPrice.trial_enabled &&
        !selectedPrice.trial_requires_upfront_payment
      ) {
        redirectToCheckoutConfirmation(
          checkoutResponseBody,
          checkoutConfirmationUrl,
        );
        return;
      }

      // let's create the payment in Stripe using payment intent secret key
      await processPaymentInStripe(checkoutResponseBody);
    } else {
      const checkoutResponseError = await checkoutResponse.json();
      handleCheckoutAttemptError(checkoutResponseError);
    }
  };

  const onSubmitMutation = useMutation<any, any, any>(async data => {
    const formData = data;
    if (getReferral()) {
      formData.referral_metadata = getReferral();
    }
    setIsProcessingPayment(true);
    try {
      if (isCardInfoRequired) {
        // Digital wallets (e.g. Apple and Google Pay) seem to have an acceptable time window between the
        // time the user clicks the pay button and the time the Payment Element is submitted. If we do not
        // do this as soon as possible, Apple/Google Pay will fail to load with an error message that says:
        // > Something went wrong. Unable to show Google Pay. Please choose a different payment method and try again.
        //
        // See PR for more context: https://github.com/circleco/circle/pull/22389
        if (isPaywallDigitalWalletEnabled) {
          const didSubmitWork = await submitStripeElement();
          if (!didSubmitWork) {
            return;
          }
        }

        if (isSetupIntentRequired) {
          // Request BE to create a SetupIntent on Stripe.
          const prepareResponse = await paywallCheckoutApi.prepare_DEPRECATED(
            currentCommunity.id,
            selectedPrice.id,
          );
          if (!prepareResponse.ok) {
            const error = await prepareResponse.json();
            handleCheckoutAttemptError(error);
            setIsProcessingPayment(false);
            return;
          }
          const prepareResponseBody = await prepareResponse.json();
          const setupIntentSecret =
            prepareResponseBody.setup_intent.client_secret;

          // Request Stripe to confirm setup (`stripe.confirmSetup`).
          let setupIntentResult = await stripe.retrieveSetupIntent(
            setupIntentSecret,
          );
          if (
            !setupIntentResult.setupIntent ||
            setupIntentResult.setupIntent.status !== "succeeded"
          ) {
            if (isPaywallDigitalWalletEnabled) {
              setupIntentResult = await stripe.confirmSetup({
                elements,
                clientSecret: setupIntentSecret,
                confirmParams: {
                  return_url: window.location.href,
                  ...paymentMethodDataParams,
                },
                redirect: "if_required",
              });
            } else {
              setupIntentResult = await stripe.confirmCardSetup(
                setupIntentSecret,
                {
                  payment_method: {
                    card: elements.getElement(CardElement),
                  },
                },
              );
            }
          }
          if (setupIntentResult.error) {
            console.error("Error: Setup error");
            handleError({
              message: setupIntentResult.error.message,
              disablePayButton: false,
            });
            setIsProcessingPayment(false);
            return;
          }
          const paymentMethodId = setupIntentResult.setupIntent.payment_method;

          formData.payment_method_processor_id = paymentMethodId; // Pass payment method ID for checkout fulfillment.
        }
      }

      // 3. Request BE to fulfill order (checkout) Create payment intent in stripe and user if needed
      await execCheckoutRequest(formData);
    } catch (error) {
      notifyBugsnag(error);
      handleError(GENERAL_ERROR);
    }
    setIsProcessingPayment(false);
    return undefined;
  });

  return { onSubmitMutation };
};
