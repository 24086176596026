import { t } from "i18n-js";
import { useIsWorkflowsV2Enabled } from "@circle-react/hooks/useIsWorkflowsV2Enabled";
import { sendEmail } from "../Actions/Member";
import { ContinueButton } from "../WorkflowForm/ContinueButton";
import { useResourceStatus } from "../WorkflowForm/useResourceStatus";
import { useCurrentAction } from "./ActionContext";
import { useActions } from "./useActions";
import { useSendEmailActionContinue } from "./useSendEmailActionContinue";

interface ActionContinueButtonProps {
  onSubmit: () => void;
}

export const ActionContinueButton = ({
  onSubmit,
}: ActionContinueButtonProps) => {
  const isWorkflowsV2Enabled = useIsWorkflowsV2Enabled();
  const { saveActions, isUpdatingAction } = useActions();
  const { action, path } = useCurrentAction();
  const { value, revalidate, hasValue } = useResourceStatus(path);

  const { handleEmailActionContinue, isLoadingMarketingSettings } =
    useSendEmailActionContinue(action);
  const actionType = isWorkflowsV2Enabled
    ? value.action_type
    : action?.action_type;

  const buttonMessage = isUpdatingAction
    ? t("settings.workflows.form.saving")
    : !hasValue
    ? t("settings.workflows.form.fill_all_fields_to_continue")
    : t("settings.workflows.form.continue");

  const isSendEmailAction = actionType === sendEmail.value;

  const handleContinue = async () => {
    const isValid = await revalidate();
    if (!isValid) return;

    const continueAction = async () => {
      await saveActions();
      onSubmit();
    };

    if (isSendEmailAction) {
      await handleEmailActionContinue({ continueAction });
    } else {
      await continueAction();
    }
  };

  return (
    <ContinueButton
      disabled={
        isUpdatingAction ||
        !hasValue ||
        (isSendEmailAction && isLoadingMarketingSettings)
      }
      onClick={handleContinue}
    >
      {buttonMessage}
    </ContinueButton>
  );
};

export default ActionContinueButton;
