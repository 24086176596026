import { useMemo } from "react";
import PropTypes from "prop-types";
import { Elements } from "@stripe/react-stripe-js";
import { Helmet } from "react-helmet";
import { FormProvider, useForm } from "react-hook-form";
import { QueryClient, QueryClientProvider } from "react-query";
import { useAppendBodyClasses } from "@/react/hooks/useAppendBodyClasses";
import { loadStripeWrapper } from "@circle-react/hooks/paywalls/useStripeElement";
import { ControlledThemeProvider, THEME_MAP } from "@circle-react/providers";
import { reactQueryConfig } from "@circle-react/providers/QueryClient/reactQueryConfig";
import { PaywallCheckoutContextProvider } from "../../../contexts/Paywalls/paywallCheckoutContext";
import { stripeElementsOptions } from "../StripeElements/stripeElementsOptions";
import { CheckoutForm } from "./CheckoutForm";
import { CommunityInfo } from "./CommunityInfo";
import { PaywallPrice } from "./PaywallPrice";
import { useInitialPrice } from "./hooks/useInitialPrice";
import "./styles.scss";

export const Checkout = props => {
  const {
    paywall,
    currentCommunity,
    currentCommunityMemberPaymentProcessorSessionClientSecret,
    currentUser,
    processorId,
    processorKey,
    isMemberAddressRequired,
    isPaywallDigitalWalletEnabled,
  } = props;

  // Once Login process can be executed at community level we can
  // render this component on App.jsx and start using usePunditUserContext
  // instead of passing current community/user as params
  const stripePromise = useMemo(
    () =>
      loadStripeWrapper({
        stripeAccountId: processorId,
        stripePublicKey: processorKey,
        locale: window.locale,
      }),
    [processorKey, processorId],
  );

  const price = useInitialPrice({ prices: paywall.prices });

  const formMethods = useForm({
    defaultValues: {
      community_id: currentCommunity.id,
      user_id: currentUser?.id,
      paywall_price_id: price.id,
      coupon_code_applied: false,
      community_member_billing_info_attributes: {},
      community_member_billing_info_address_attributes_complete:
        !isMemberAddressRequired,
      payment_method_type: "card",
    },
  });

  const queryClient = new QueryClient(reactQueryConfig);

  let title;
  if (paywall?.event?.name) {
    title = paywall.event.name;
  } else {
    title = paywall.display_name;
  }

  useAppendBodyClasses("paywall-checkout");

  const otherStripeElementsOptions = isPaywallDigitalWalletEnabled
    ? {
        mode: "subscription",
        setup_future_usage: "off_session",
        currency: paywall.currency_code,
        amount: price.amount,
      }
    : {};

  return (
    <Elements
      stripe={stripePromise}
      options={{
        ...stripeElementsOptions,
        ...otherStripeElementsOptions,
        customerSessionClientSecret:
          currentCommunityMemberPaymentProcessorSessionClientSecret,
      }}
    >
      <ControlledThemeProvider theme={THEME_MAP["default"]}>
        <QueryClientProvider client={queryClient}>
          <FormProvider {...formMethods}>
            <PaywallCheckoutContextProvider
              {...props}
              isPaywallDigitalWalletEnabled={isPaywallDigitalWalletEnabled}
            >
              <Helmet>
                <title>{title}</title>
              </Helmet>
              <div className="paywall-checkout-wrapper">
                <div className="paywall-checkout-container">
                  <div className="paywall-info">
                    <div className="paywall-info__content">
                      <CommunityInfo />
                      <PaywallPrice />
                    </div>
                  </div>
                  <div className="user-info">
                    <div className="paywall-info__content">
                      <CheckoutForm />
                    </div>
                  </div>
                </div>
              </div>
            </PaywallCheckoutContextProvider>
          </FormProvider>
        </QueryClientProvider>
      </ControlledThemeProvider>
    </Elements>
  );
};

Checkout.propTypes = {
  paywall: PropTypes.object.isRequired,
  currentCommunity: PropTypes.object.isRequired,
  isTaxCollectionEnabled: PropTypes.bool.isRequired,
  currentCommunityMember: PropTypes.object,
  hasAlreadyPurchased: PropTypes.bool,
  currentUser: PropTypes.object,
  loginUrl: PropTypes.string.isRequired,
  communityRootUrl: PropTypes.string.isRequired,
  processorId: PropTypes.string.isRequired,
  checkoutConfirmationUrl: PropTypes.string.isRequired,
  processorKey: PropTypes.string.isRequired,
  areCouponsEnabled: PropTypes.bool.isRequired,
  isMemberAddressRequired: PropTypes.bool.isRequired,
  memberBillingInfo: PropTypes.object,
  isPaywallDigitalWalletEnabled: PropTypes.bool,
};
