import { BaseResource } from "@circle-react/components/SettingsApp/Workflows/components/Events/BaseResource";
import { t } from "@circle-react/custom_i18n";

export interface FormResourcePropType {
  resourceId: number;
}

export const FormResource = ({ resourceId }: FormResourcePropType) => (
  <BaseResource
    label={t("settings.workflows.form.events.received_form_submission.form")}
    value={resourceId.toString()}
  />
);
