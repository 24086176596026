import type { IconType } from "@circle-react/components/shared/Icon";
import { Icon } from "@circle-react/components/shared/Icon";

export interface ExternalLinkProps {
  dataTestId?: string;
  disabled?: boolean;
  iconName: IconType;
  label: string;
  linkTo: string;
  show?: boolean;
}

export const ExternalLink = ({
  dataTestId,
  disabled = false,
  iconName,
  label,
  linkTo,
  show = true,
}: ExternalLinkProps) => {
  if (!show) {
    return null;
  }

  return (
    <a
      data-testid={dataTestId}
      href={linkTo}
      className="text-c-sidebar focus:text-c-sidebar hover:text-c-sidebar hover:bg-c-sidebar-hover focus:bg-c-sidebar-hover my-px flex w-full cursor-pointer cursor-pointer items-center rounded-md bg-transparent px-2 py-1.5 text-sm"
      onClick={event => {
        if (disabled) {
          event.preventDefault();
        }
      }}
      title={label}
    >
      <Icon type={iconName} size={16} aria-hidden />
      <div className="ml-2 flex-1">
        <h3 className="max-w-full truncate text-sm font-normal text-current">
          {label}
        </h3>
      </div>
    </a>
  );
};
